import React from "react";
import cx from "classnames";
import { HeadingContext } from "./HeadingContext";
import styled from "styled-components";

const levelClasses = ({ level }) => {
  switch(level) {
    case "display-1":
      return "py-xl";
    case "display-2": 
      return "py-l";
    case "display-3":
      return "py-m";
    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6":
      return "py-m";
    default: 
      return null;
  }
}

const Header = styled.header.attrs(props => ({
  className: cx(levelClasses(props))
}))``;

export const Heading = (props) => {
  return (
    <HeadingContext.Provider value={{ level: props.level }}>
      <Header {...props} />
    </HeadingContext.Provider>
  )
}
