import React from "react"

import { StrapiDesktopNavigation } from "./StrapiDesktopNavigation";
import { StrapiTabletNavigation } from "./StrapiTabletNavigation";
import { StrapiMobileNavigation } from "./StrapiMobileNavigation";
import { useBreakpoint } from "../util/useBreakpoint"
import breakpoints from "../breakpoints"


export const StrapiNavigation = () => {
  const breakpoint = useBreakpoint(breakpoints, "md")

  switch (breakpoint) {
    case "sm":
      return <StrapiMobileNavigation />
    case "md":
      return <StrapiTabletNavigation />
    default:
      return <StrapiDesktopNavigation />
  }
};