import styled from "styled-components"
import { theme } from "twin.macro"

export const FooterLinks = styled.div.attrs({
  className: "mt-l md:mt-0"
})`
  @media (max-width: ${theme("screens.md")}) {
  }
  @media (min-width: ${theme("screens.md")}) {
    grid-row-start: row1-start;
    grid-row-end: row2-end;
  }
`
