import React, { useRef, useEffect, useMemo, useState } from "react"
import { createPopper } from "@popperjs/core"
import { PopoutContext } from "./PopoutContext"

import styled, { css } from "styled-components"

const placementStyle = ({ placement = "top" }) => {
  const positioning = placement.split("-")
  const [side] = positioning

  const flexDirectionType =
    side === "left" || side === "right" ? "row" : "column"
  const flexDirectionOrder =
    side === "left" || side === "top" ? "reverse" : undefined

  const flexDirection = `${flexDirectionType}${
    flexDirectionOrder ? `-${flexDirectionOrder}` : ``
  }`

  return css`
    flex-direction: ${flexDirection};
  `
}

const StyledPopout = styled.div`
  display: flex;
  ${placementStyle}
`

export const Popout = ({
  to,
  children,
  instance,
  placement = "auto",
  ...rest
}) => {
  const [usedPlacement, setUsedPlacement] = useState()

  const popoutRef = useRef()
  const arrowRef = useRef()
  const popperInstance = useRef()

  const modifier = useMemo(
    () => ({
      name: "placementUpdater",
      enabled: true,
      phase: "main",
      fn({ state }) {
        setUsedPlacement(state.placement)
      },
    }),
    []
  )

  // eslint-disable-next-line no-undef
  useEffect(() => {
    if (popperInstance.current) popperInstance.current.destroy();

    if(to) {

      const popper = createPopper(to, popoutRef.current, {
        placement,
        ...(arrowRef.current
          ? {
            
              modifiers: [
                modifier,
                {
                  name: 'computeStyles',
                  options: {
                    adaptive: false, // true by default
                  },
                },
                {
                  name: "arrow",
                  options: {
                    element: arrowRef.current,
                  },
                },
              ],
            }
          : {}),
      })
      popperInstance.current = popper;

      return popper?.destroy
    }
  }, [modifier, placement, to]);

  return (
    <StyledPopout {...rest} ref={popoutRef} placement={usedPlacement}>
      <PopoutContext.Provider value={{ arrowRef, placement: usedPlacement }}>
        {children}
      </PopoutContext.Provider>
    </StyledPopout>
  )
}
