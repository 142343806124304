import React from "react"
import { useStrapiNavigationAction } from "./useStrapiNavigationAction"; 
import { NavigationLink } from "../components/Navigation";

export const StrapiNavigationAction = ({ id, ...rest }) => {
  const action = useStrapiNavigationAction(id) || {};
  
  const type = action.style && action.color
  ? `${action.color}-${action.style}`
  : null;

  const icon = action.icon?.localFile?.publicURL && (
    <img src={action.icon?.localFile?.publicURL} alt={action.label.text} />
  );

  return (
    <NavigationLink type={type} icon={icon} {...rest} {...action.props} />
  );
}
