import React, { useCallback, useRef, useState, useMemo, useEffect } from "react"
import debounce from "debounce";
import styled from "styled-components"
import { NavigationPopoutContext } from "./NavigationPopoutContext"
import cx from "classnames"
import { PopoutContext, PopoutArrow, PopoutContent } from "../Popout"

const StyledNavigation = styled.div.attrs({
  className: `flex w-full justify-end`,
})``

const NavigationPopoutArrow = () => {
  return <PopoutArrow />
}

const NavigationPopoutContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
`

const NavigationPopout = React.forwardRef(
  ({ to, className, style: styleProp, ...rest }, forwardedRef) => {
    const ref = useRef()

    const [style, setStyle] = useState({
      display: "none",
    })
    const popoutContextValue = useMemo(() => {
      return {
        ref,
        placement: "bottom",
        to,
      }
    }, [to])

    useEffect(() => {
      if (to && ref.current) {
        setStyle(({ display, ...restStyles }) => ({ ...restStyles }))
        setTimeout(() => {
          const toBBox = to.getBoundingClientRect()
          const popoutBBox = ref.current.getBoundingClientRect()

          setStyle(style => ({
            ...style,
            position: "fixed",
            left: toBBox.left + toBBox.width / 2 - popoutBBox.width / 2,
            top: toBBox.bottom,
          }))
        }, 0)
      }
    }, [to])

    return (
      <PopoutContext.Provider value={popoutContextValue}>
        <NavigationPopoutContentWrapper
          ref={ref}
          className={className}
          style={{ ...style, ...styleProp }}
          {...rest}
        >
          <PopoutContent ref={forwardedRef}></PopoutContent>
          <NavigationPopoutArrow />
        </NavigationPopoutContentWrapper>
      </PopoutContext.Provider>
    )
  }
)

export const Navigation = ({ children }) => {
  const popoutInstanceRef = useRef()
  const contentRef = useRef()

  const [state, setState] = useState({
    active: null,
    popoutTarget: undefined,
    showPopout: false,
    didLeave: false,
  })

  const leaveTimeout = useRef()

  const toggle = useMemo(() => {
    return debounce((visible) => setState(state => ({
      ...state, 
      showPopout: visible
    })), 200);
  }, []);

  const leave = useCallback(() => {
    toggle(false);    
  }, [toggle])

  const show = useCallback(() => {
    toggle(true);
  }, [toggle]);

  const activate = useCallback(
    el => {
      if (leaveTimeout.current) clearTimeout(leaveTimeout.current)
      const token = new Date()
      setState(state => ({
        ...state,
        active: token,
        popoutTarget: el,
      }))
      show();
      return token
    },
    [setState, show]
  )

  const transitionClasses = cx(
    "absolute",
    state.active > 0 ? "transition-all" : "transition-opacity",
    !state.showPopout ? "opacity-0" : "opacity-100",
    "duration-200",
    "ease-in-out"
  )

  const popoutContextValue = useMemo(
    () => ({
      activate,
      leave,
      active: state.active,
      ref: contentRef,
    }),
    [activate, state.active, leave]
  )

  const handleMouseOver = useCallback(() => {
    show();
  }, [show]);

  const handleMouseLeave = useCallback((e) => {
    if(contentRef.current.contains(e.relatedTarget)) {
      return;
    }
    leave();
  }, [leave]);

  return (
    <StyledNavigation>
      <NavigationPopoutContext.Provider value={popoutContextValue}>
        {children}
        <NavigationPopout
          to={state.popoutTarget}
          instance={popoutInstanceRef}
          placement="bottom"
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          className={cx(transitionClasses, !state.showPopout && "left-full")}
          ref={contentRef}
        />
      </NavigationPopoutContext.Provider>
    </StyledNavigation>
  )
}
