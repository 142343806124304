import React, { useContext } from "react";
import { PopoutContext } from "./PopoutContext";
import styled, { css } from "styled-components";

const topArrow = css`
    bottom: 0;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  `

const bottomArrow = css`
    top: 0;
    clip-path: polygon(50% 0%, 0 100%, 100% 100%);
  `
const horizontalStartArrow =css`
    margin-right: 5px;
  `

const horizontalEndArrow = css`
    margin-left: 5px;
  `
const verticalStartArrow = css`
    margin-bottom: 5px;
  `

const verticalEndArrow = css`
    margin-top: 5px;
  `

const rightArrow = css`
    left: 0;
    clip-path: polygon(100% 0%, 0 50%, 100% 100%);
  `

const leftArrow = css`
    right: 0;
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  `

const arrowStyles = {
  top: topArrow,
  "top-start": css`
    ${topArrow}
    ${horizontalStartArrow}
  `,
  "top-end": css`
    ${topArrow}
    ${horizontalEndArrow}
  `,
  right: rightArrow,
  "right-start": css`
    ${rightArrow}
    ${verticalStartArrow}
  `,
  "right-end": css`
    ${rightArrow}
    ${verticalEndArrow}
  `,
  bottom: bottomArrow,
  "bottom-start": css`
    ${bottomArrow}
    ${horizontalStartArrow}
  `,
  "bottom-end": css`
    ${bottomArrow}
    ${horizontalEndArrow}
  `,
  left: leftArrow,
  "left-start": css`
    ${leftArrow}
    ${verticalStartArrow}
  `,
  "left-end": css`
    ${leftArrow}
    ${verticalEndArrow}
  `,
};

const sizingStyle = (props) => {
  switch(props.placement) {
    case "top":
    case "bottom": 
      return css`
          width: calc(0.8em + 2px);
          height: calc(0.4em + 2px);
          &:before {
            width: calc(0.8em + 2px);
            height: calc(0.4em + 2px);
          }
          &:after {
            width: 0.8em;
            height: 0.4em;
          }
      `
    case "left":
    case "right":
      return css`
        height: calc(0.8em + 2px);
        width: calc(0.4em + 2px);
        &:before{
          height: calc(0.8em + 2px);
          width: calc(0.4em + 2px);
        }
        &:after {
          height: calc(0.8em);
          width: calc(0.4em);
        }
    `
    default: return null;
  }
}

const offsetBorderStyle = (props) => {
  switch(props.placement) {
    case "top":
      return css`
        bottom: 2px;
        &:after {
          bottom: 1px !important;
          left: 1px !important;
        }
      `;
    case "right":
      return css`
        left: 1px;
        top: 0.2em !important;
        &:after {
          top: 1px !important;
          left: 2px !important;
        }
      `;
    case "bottom":
      return css`
        top: 1px;
        &:after {
          top: 2px !important;
          left: 1px !important;
        }
      `;
    case "left":
      return css`
        right: 1px;
        top: 0.2em !important;
        &:after {
          top: 1px !important;
          right: 2px !important;
        }
      `;
    default: return null;
  }
}

const Shape = styled.div`
  ${offsetBorderStyle};
  ${sizingStyle};
  overflow: hidden;
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    display: block;
    ${props => arrowStyles[props.placement]};
    /*background-color: #e2e8f0;*/
  }
  &:after {
    content: ' ';
    position: absolute;
    display: block;
    ${props => arrowStyles[props.placement]};
    background-color: white;
  }
`;

const Container = styled.div`
  height: ${props => props.placement === "top" || props.placement === "bottom" ? `calc(0.4em + 2px)` : `calc(0.8em + 2px)`};
  width: ${props => props.placement === "top" || props.placement === "bottom" ? `calc(0.8em + 2px)` : `calc(0.4em + 2px)`};
`;

export const PopoutArrow = (props) => {
  const { arrowRef, placement } = useContext(PopoutContext);
  return (
    <Container {...props} placement={placement} ref={arrowRef} className="z-50">
      <Shape ref={arrowRef} placement={placement} >
        &nbsp;
      </Shape>
    </Container>
  );
}