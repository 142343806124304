import React, { useState } from "react"
import cx from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import { NavigationGroup, NavigationItem } from "../components/Navigation"
import { MdClose } from "react-icons/md"
import {
  SlidePanel,
  SlidePanelHeading,
  SlidePanelBody,
} from "../components/SlidePanel"
import { Navigation } from "../components/Navigation"
import { CircleButton } from "../components/CircleButton"
import { Hamburger } from "../components/icons"
import { Separator } from "../components/Separator"
import { StrapiNavigationAction } from "./StrapiNavigationAction"
import { StrapiLanguageSelect } from "./StrapiLanguageSelect"
import { StrapiMobileNavigationItem } from "./StrapiMobileNavigationItem"
import { StrapiNavigationGroupItemRenderer } from "./StrapiNavigationGroupItemRenderer"
import * as constants from "./strapiComponentConstants"

export const StrapiTabletNavigation = () => {
  const [panelVisible, setPanelVisible] = useState(false)
  const data = useStaticQuery(
    graphql`
      query TabletNavigationQuery {
        strapi {
    tabletNavigation {
      menuItems {
        ... on STRAPI_ComponentNavigationMenuReference {
          id
          menu {
            id
          }
        }
        ... on STRAPI_ComponentNavigationNavigationActionReference {
          id
          action {
            id
          }
        }
      }
      navigation_groups {
        items {
          ... on STRAPI_ComponentNavigationMenuReference {
            id
            menu {
              id
            }
          }
          ... on STRAPI_ComponentNavigationNavigationActionReference {
            id
            action {
              id
            }
          }
          ... on STRAPI_ComponentLanguageLangSelectReference {
            id
          }
        }
        id
        name
      }
      menuBottomActions {
        ... on STRAPI_ComponentNavigationNavigationActionReference {
          id
          action {
            id
          }
        }
        ... on STRAPI_ComponentLanguageLangSelectReference {
          id
        }
      }
      menuTopAction {
        action {
          id
        }
        id
      }
    }
  }
      }
    `
  )

  const groups = data?.strapi.tabletNavigation?.navigation_groups || []

  const renderedGroups = groups.map(group => (
    <NavigationGroup key={group.name}>
      {group.items.map(data => (
        <StrapiNavigationGroupItemRenderer data={data} key={data.id} />
      ))}
    </NavigationGroup>
  ))

  const topAction = data?.strapi.tabletNavigation.menuTopAction.action

  const menuItems = data?.strapi.tabletNavigation.menuItems.map(data => (
    <React.Fragment key={data.id}>
      <StrapiMobileNavigationItem data={data} />
      <Separator />
    </React.Fragment>
  ))

  const bottomActions = data?.strapi.tabletNavigation.menuBottomActions.map(
    data => {
      const key = `${data.__typename}--${data.id}`
      switch (data.__typename) {
        case constants.NAVIGATION_ACTION_REFERENCE:
          return (
            <NavigationItem key={key}>
              <StrapiNavigationAction id={data.action.id} />
            </NavigationItem>
          )
        case constants.NAVIGATION_LANGUAGE_SELECT_REFERENCE:
          return (
            <NavigationItem key={key}>
              <StrapiLanguageSelect size="m" />
            </NavigationItem>
          )
        default:
          return null
      }
    }
  )

  return (
    <Navigation>
      {renderedGroups}
      <NavigationGroup>
        <NavigationItem>
          <CircleButton
            size="m"
            type="gray"
            onClick={() => setPanelVisible(true)}
          >
            <Hamburger />
          </CircleButton>
        </NavigationItem>
      </NavigationGroup>
      <SlidePanel from={"right"} visible={panelVisible}>
        <div className={cx("w-96", "h-screen")}>
          <SlidePanelHeading>
            <StrapiNavigationAction id={topAction.id} />
            <CircleButton
              size="m"
              className="ml-m"
              type="gray"
              onClick={() => setPanelVisible(false)}
            >
              <MdClose />
            </CircleButton>
          </SlidePanelHeading>
          <SlidePanelBody>
            {menuItems}
            <div className="my-s flex justify-end">{bottomActions}</div>
          </SlidePanelBody>
        </div>
      </SlidePanel>
    </Navigation>
  )
}
