import React from "react"
import {
  Footer,
  FooterLogo,
  FooterLinksHeader,
  FooterLinks,
  FooterLink,
  FooterSocial,
  FooterSocialLink,
} from "../components/Footer"
import { useContext } from "react";
import { PageContext } from "../contexts/PageContext";
import { FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa"

export const StrapiFooter = () => {
  const { data } = useContext(PageContext);

  const logoAltText = data.strapi.footer.logoAltText.text;
  const logo = (
    <img src={data.strapi.footer.logo.localFile.publicURL} alt={logoAltText} />
  )

  const groups = data.strapi.footer.groups.map(group => (
    <FooterLinks key={group.id}>
      <FooterLinksHeader>{group.heading.text}</FooterLinksHeader>
      {group.links.map(link => (
        <FooterLink key={link.id} href={link.location.href}>
          {link.label.text}
        </FooterLink>
      ))}
    </FooterLinks>
  ))

  return (
    <Footer className={"mt-xl"}>
      <FooterLogo>{logo}</FooterLogo>
      <FooterSocial>
        <FooterSocialLink href={data.strapi.footer.twitterUrl.text}>
          <FaTwitter />
        </FooterSocialLink>
        <FooterSocialLink href={data.strapi.footer.facebookUrl.text}>
          <FaFacebook />
        </FooterSocialLink>
        <FooterSocialLink href={data.strapi.footer.linkedinUrl.text}>
          <FaLinkedin />
        </FooterSocialLink>
      </FooterSocial>
      {groups}
    </Footer>
  )
}
