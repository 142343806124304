import React from "react";
import * as constants from "./strapiComponentConstants";

import { StrapiNavigationMenu } from "./StrapiNavigationMenu";
import { StrapiLanguageSelect } from "./StrapiLanguageSelect";
import { StrapiNavigationAction } from "./StrapiNavigationAction";

import { NavigationItem } from "../components/Navigation";

export const StrapiNavigationGroupItemRenderer = ({ data }) => {
  switch (data["__typename"]) {
    case constants.NAVIGATION_MENU_REFERENCE:
      return <StrapiNavigationMenu id={data.menu.id} />
    case constants.NAVIGATION_LANGUAGE_SELECT_REFERENCE:
      return (
        <NavigationItem>
          <StrapiLanguageSelect size="m" />
        </NavigationItem>
      )
    case constants.NAVIGATION_ACTION_REFERENCE:
      return (
        <NavigationItem>
          <StrapiNavigationAction id={data.action.id} size="m" />
        </NavigationItem>
      )
    default:
      return null
  }
}