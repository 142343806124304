import React, { useRef, useContext, useCallback, useState, } from "react";
import { createPortal } from "react-dom";
import { NavigationPopoutContext } from "./NavigationPopoutContext";
import styled from "styled-components"
import { Button } from "../Button"

const StyledButton = styled(Button).attrs({
  type: "naked",
  size: "s",
  className: "hover:underline",
})``
 
const NavigationMenuContent = styled.div`
  position: relative;
  max-height: 9999px;
  max-width: 9999px;
`;

export const NavigationMenu = ({ label, children }) => {
  const { ref: popoutRef, activate, leave, active } = useContext(NavigationPopoutContext);

  const ref = useRef();
  const [token, setToken] = useState(null);
  const popoutContentRef = useRef();

  const show = active === token;

  const handleEnterAnchor = useCallback(() => {
    setToken(activate(ref.current));
  }, [activate]);

  const handleLeaveAnchor = useCallback(() => {
    leave();
  }, [leave]);
  
  return (
    <>
      <StyledButton
        ref={ref}
        onMouseOver={handleEnterAnchor}
        onMouseLeave={handleLeaveAnchor}
        onFocus={handleEnterAnchor}
      >
        {label}
      </StyledButton>
      {(
        popoutRef.current ? createPortal(
          (<NavigationMenuContent ref={popoutContentRef}>{show && children}</NavigationMenuContent>),
          popoutRef.current
        ) : null
      )}
      
    </>
  )
}
