import React, { useState, useEffect } from "react"
import { Container } from "./Container";
import cx from "classnames"
import styled from "styled-components"
import PropTypes from "prop-types"

const Wrapper = styled.div.attrs(props => ({
  className: cx(
    "flex",
    "flex-row",
    "items-center",
    "bg-white",
    "transition",
    "ease-in-out",
    "duration-500",
    "z-40",
    "w-full",
    props.isScrolled && "shadow"
  ),
}))`
  height: 60px;
`

export const Content = styled.div.attrs({
  className: "flex-1",
})``

export const TopBar = ({ children, logo }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const checkOffset = () => {
      const scrollTop =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop
      setIsScrolled(scrollTop > 0)
    }
    window.addEventListener("scroll", checkOffset)
    checkOffset();
    return () => window.removeEventListener("scroll", checkOffset)
  }, [isScrolled])

  return (
    <Wrapper isScrolled={isScrolled}>
      <Container className="flex">
        {logo}
        <Content>{children}</Content>
      </Container>
    </Wrapper>
  )
}

TopBar.propTypes = {
  children: PropTypes.node,
}
