import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import cx from "classnames";

const sizeClasses = {
  s: 'p-xxs',
  m: 'p-s',
  l: 'p-m',
}

const typeClasses = {
  primary: `bg-blue-500 text-white hover:bg-blue-600 hover:shadow`,
  secondary: `bg-green-500 hover:bg-green-600 hover:shadow`,
  'primary-outline': `border-blue-500 hover:shadow hover:border-blue-600`,
  'secondary-outline': `border-green-500 hover:shadow hover:border-green-600`,
  'gray': 'bg-gray-100 text-blue-500 hover:bg-gray-200 hover:shadow',
};

const StyledButton = styled.button.attrs((props) => ({
  className: cx(
    typeClasses[props.type],
    sizeClasses[props.size],
    "border-transparent",
    "inline-block",
    "rounded-full",
    "tracking-wider",
    "transition",
    "ease-in-out",
    "duration-200",
    "border-2"
  )
}))``;

export const CircleButton = React.forwardRef((props, ref) => {
  return (
    <StyledButton
      {...props}
      ref={ref}
      forwardedAs={props.as}
    />)
});

CircleButton.defaultProps = {
  as: "button",
  size: "m",
  type: "primary"
};