import React from "react"
import { TopBar } from "../components/TopBar"
import { useStaticQuery, graphql } from "gatsby"

export const StrapiTopBar = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query TopBarQuery {
        strapi {
          topBar {
            logo { 
              url
              localFile {
                publicURL
              }
            }
          }
        }
  
      }
    `
  )

  const logo = <img src={data.strapi.topBar.logo.localFile.publicURL} alt="QANDR Logo" />;

  return <TopBar logo={logo}>{children}</TopBar>
}
