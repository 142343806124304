import React, { useState } from "react"
import cx from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import { NavigationGroup, NavigationItem } from "../components/Navigation"
import { MdClose } from "react-icons/md"
import {
  SlidePanel,
  SlidePanelHeading,
  SlidePanelBody,
} from "../components/SlidePanel"
import { Navigation } from "../components/Navigation"
import { CircleButton } from "../components/CircleButton"
import { Hamburger } from "../components/icons"
import { Separator } from "../components/Separator"
import { StrapiNavigationAction } from "./StrapiNavigationAction"
import { StrapiLanguageSelect } from "./StrapiLanguageSelect"
import { StrapiMobileNavigationItem } from "./StrapiMobileNavigationItem"
import * as constants from "./strapiComponentConstants"

export const StrapiMobileNavigation = () => {
  const [panelVisible, setPanelVisible] = useState(false)
  const data = useStaticQuery(
    graphql`
      query MobileNavigationQuery {
        strapi {
          mobileNavigation {
            id
            menuItems {
              __typename
              ... on STRAPI_ComponentNavigationMenuReference {
                id
                menu {
                  id
                }
              }
              ... on STRAPI_ComponentNavigationNavigationActionReference {
                id
                action {
                  id
                }
              }
            }
            menuBottomActions {
              __typename
              ... on STRAPI_ComponentNavigationNavigationActionReference {
                id
                action {
                  id
                }
              }
              ... on STRAPI_ComponentLanguageLangSelectReference {
                id
              }
            }
            topAction {
              id
            }
          }
        }
      }
    `
  )

  const topAction = data?.strapi.mobileNavigation.topAction

  const menuItems = data?.strapi.mobileNavigation.menuItems.map(data => (
    <>
      <StrapiMobileNavigationItem data={data} key={data.menu.id} />
      <Separator />
    </>
  ))

  const bottomActions = data?.strapi.mobileNavigation.menuBottomActions.map(
    data => {
      switch (data.__typename) {
        case constants.NAVIGATION_ACTION_REFERENCE:
          return (
            <NavigationItem>
              <StrapiNavigationAction id={data.action.id} key={data.id}/>
            </NavigationItem>
          )
        case constants.NAVIGATION_LANGUAGE_SELECT_REFERENCE:
          return (
            <NavigationItem>
              <StrapiLanguageSelect size="m" />
            </NavigationItem>
          )
        default:
          return null
      }
    }
  )

  return (
    <Navigation>
      <NavigationGroup>
        <NavigationItem>
          <CircleButton
            size="m"
            type="gray"
            onClick={() => setPanelVisible(true)}
          >
            <Hamburger />
          </CircleButton>
        </NavigationItem>
      </NavigationGroup>
      <SlidePanel from={"top"} visible={panelVisible}>
        <div className={cx("w-full", "h-screen")}>
          <SlidePanelHeading className="justify-end">
            <StrapiNavigationAction id={topAction.id} />
            <CircleButton
              size="m"
              className="ml-m"
              type="gray"
              onClick={() => setPanelVisible(false)}
            >
              <MdClose />
            </CircleButton>
          </SlidePanelHeading>
          <SlidePanelBody>
            {menuItems}
            <div className="my-s flex justify-end">{bottomActions}</div>
          </SlidePanelBody>
        </div>
      </SlidePanel>
    </Navigation>
  )
}
