import { useStaticQuery, graphql } from "gatsby"

export const useStrapiLanguages = () => {
  const data = useStaticQuery(
    graphql`
      query StrapiLanguagesQuery {
        strapi {
          langSelect {
            language {
              code
              label
            }
          }
        }
      }
    `
  )

  const languages =
    data.strapi.langSelect.language.map(langItem => langItem.code) || []

  return languages
}
