import { useContext } from "react";
import { PageContext } from "../contexts/PageContext";

export const useStrapiNavigationAction = id => {
  const { data } = useContext(PageContext);

  const action = data.strapi.navigationActions.find(node => {
    return node.id === id
  });

  return {
    ...action,
    props: {
      href: action.location.href,
      children: action?.label.text,
    }
  }
}
