import React from "react"
import {
  Collapsible,
  CollapsibleButton,
  CollapsibleContent,
} from "../components/Collapsible"
import { StrapiNavigationAction } from "./StrapiNavigationAction";
import { MobileNavigationActionRenderer } from "./MobileNavigationActionRenderer";
import { Row, Column } from "../components";
import { useStrapiNavigationMenu } from "./useStrapiNavigationMenu";

const SubMenuRenderer = ({ id }) => {
  const menu = useStrapiNavigationMenu(id);

  const maxRows = Math.ceil(menu.children.length / 3);

  const columns = menu.children.reduce(
    (acc, curr, index) => {
      const col = Math.floor(index / maxRows);
      acc[col].push(curr)
      return acc
    },
    [[], [], []]
  );

  return (
    <>
      <div className="text-sm font-semibold">{menu.label.text}</div>
      <Row>
        {columns.map((links, index) => (
          <Column key={index} className="mr-s">
            {links.map(link => (
              <MobileNavigationActionRenderer key={link.action.id} id={link.action.id} level={4} />
            ))}
          </Column>
        ))}
      </Row>
    </>
  )
}

const StrapiMobileNavigationMenu = ({ id }) => {
  const menu = useStrapiNavigationMenu(id);

  const children = menu.children.map(
    (child) => {
      switch(child.__typename) {
        case "STRAPI_ComponentNavigationNavigationActionReference":
          return <MobileNavigationActionRenderer id={child.action.id} level={3} />
        case "STRAPI_ComponentNavigationMenuReference":
          return <SubMenuRenderer id={child.menu.id} />
        default: return null;
      }
    }
  );

  return (
    <Collapsible className="w-full">
      <CollapsibleButton className={"font-semibold"}>{menu.label.text}</CollapsibleButton>
      <CollapsibleContent> 
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
}

export const StrapiMobileNavigationItem = ({ data }) => {
   switch(data.__typename) {
    case "STRAPI_ComponentNavigationMenuReference":
      return <StrapiMobileNavigationMenu id={data.menu.id} />
    default: return null;
  }
}