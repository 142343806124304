import React, { useCallback, useContext } from "react"
import { navigate } from "gatsby";
import { useStrapiLanguages } from "./useStrapiLanguages";
import { usePreferredLanguage } from "../util/usePreferredLanguage";
import { PageContext } from "../contexts/PageContext";

const LazyDropDown = React.lazy(() =>
import("../components/DropDown").then((module) => ({ default: module.DropDown })));

export const StrapiLanguageSelect = (props) => {
  const { language, languageLinks } = useContext(PageContext);

  const [,setPreferredLanguage] = usePreferredLanguage();
  const languages = useStrapiLanguages();

  const handleChange = useCallback((val) => {
    const lang = val.selectedItem?.value?.toLowerCase();
    setPreferredLanguage(lang);
    navigate(languageLinks[lang]);
  }, [setPreferredLanguage, languageLinks]);

  const isSSR = typeof window === "undefined"
  const selected = languages.find(lang => lang.toLowerCase() === language.toLowerCase());

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <LazyDropDown items={languages} onChange={handleChange} value={selected} {...props}/>
        </React.Suspense>
      )}
    </>
  )
}
