import React, { useContext, forwardRef } from "react";

import styled from "styled-components";

import { PopoutContext } from "./PopoutContext";
const StyledPopoutContent = styled.div.attrs({ className: "shadow-m bg-white p-m rounded-xl z-50" })``;

export const PopoutContent = forwardRef((props, ref) => {
  const { placement } = useContext(PopoutContext);

  return (
    <StyledPopoutContent {...props} placement={placement} ref={ref} />
  )
})