import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  Navigation,
  NavigationGroup,
} from "../components/Navigation"

import { StrapiNavigationGroupItemRenderer } from "./StrapiNavigationGroupItemRenderer";

export const StrapiDesktopNavigation = () => {
  const data = useStaticQuery(
    graphql`
      query DesktopNavigationQuery {
        strapi {
          desktopNavigation {
            navigation_groups {
              name
              items {
                __typename
                ... on STRAPI_ComponentNavigationMenuReference {
                  id
                  menu {
                    id
                  }
                }
                ... on STRAPI_ComponentNavigationNavigationActionReference {
                  id
                  action {
                    id
                  }
                }
                ... on STRAPI_ComponentLanguageLangSelectReference {
                  id
                }
              }
            }
          }
        }
      }
    `
  )

  const groups = data?.strapi.desktopNavigation.navigation_groups || []

  const renderedGroups = groups.map(group => (
    <NavigationGroup key={group.name}>
      {group.items.map(data => (
        <StrapiNavigationGroupItemRenderer data={data} key={data.id} />
      ))}
    </NavigationGroup>
  ))

  return <Navigation>{renderedGroups}</Navigation>
}
